<template>
  <div class="goodsOwnerManage">
    <div class="facilityBox">
      <!-- 表单区域 -->
      <div class="searchBox" v-if="activePage != 4">
        <el-form inline :model="searchForm" ref="searchForm">
          <el-form-item label="企业名称：" prop="EnterpriseFullName" label-width="85px" v-if="activePage != 2">
            <el-input v-model="searchForm.EnterpriseFullName" placeholder="请输入企业名称" @keyup.enter.native="search()">
            </el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="LegalPersonlPhone" v-if="activePage == 2">
            <el-input v-model="searchForm.LegalPersonlPhone" placeholder="请输入手机号" @keyup.enter.native="search()">
            </el-input>
          </el-form-item>
          <el-form-item label="签订日期：" prop="field3" label-width="85px" v-if="activePage != 2">
            <el-date-picker v-model="searchForm.dateTime" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label-width="0px">
            <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-tabs v-model="activePage" class="barWrap" @tab-click="cmptChange">
        <el-tab-pane label="服务商" name="0"></el-tab-pane>
        <el-tab-pane label="代理商" name="1"></el-tab-pane>
        <el-tab-pane label="个人" name="2"></el-tab-pane>
        <el-tab-pane label="其他" name="3"></el-tab-pane>
        <el-tab-pane label="电签" name="4"></el-tab-pane>
      </el-tabs>
      <el-tabs v-model="signStatus" class="barWrap" @tab-click="statusChange" v-if="activePage == 4">
        <el-tab-pane label="待签署" name="0"></el-tab-pane>
        <el-tab-pane label="已拒绝" name="1"></el-tab-pane>
        <el-tab-pane label="已同意" name="100"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" width="200"
        v-loading="loading" v-if="activePage != 4" key="1">
        <el-table-column align="center" type="index" prop="UserID" label="序号" width="50" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="姓名" show-overflow-tooltip
          v-if="activePage == 2"></el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="身份证号" show-overflow-tooltip v-if="activePage == 2">
        </el-table-column>
        <el-table-column align="center" prop="Phone" label="手机号" show-overflow-tooltip
          v-if="activePage == 2"></el-table-column>
        <el-table-column align="center" prop="SignDatetime" label="签订日期" show-overflow-tooltip v-if="activePage != 0">
        </el-table-column>
        <el-table-column align="center" prop="CreatedTime" label="签订日期" show-overflow-tooltip v-else>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="goFacility(scope.row)" icon="el-icon-tickets">管理合同
            </el-button>
            <el-button type="primary" size="small" @click="goContractList(scope.row)" icon="el-icon-tickets"
              v-if="activePage == 3">委托协议</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="tableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading" v-else
        key="2">
        <el-table-column align="center" type="index" prop="UserID" label="序号" width="50" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseName" label="发起企业" width="250"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="PCTTypeName" label="合同类型" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="InvoiceTypeName" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskTypeName" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneTypeName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="费率" width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.Rate }}%</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="技术服务费费率" width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.TecRate }}%</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="发票费率" width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.DefaultRate }}%</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="SignDate" label="电签时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="AgreeTime" label="同意时间" width="200" show-overflow-tooltip
          v-if="signStatus == 100">
        </el-table-column>
        <el-table-column align="center" prop="AgreeTime" label="拒绝时间" width="200" show-overflow-tooltip
          v-if="signStatus == 1">
        </el-table-column>
        <el-table-column align="center" prop="Remark" label="拒绝原因" width="200" show-overflow-tooltip
          v-if="signStatus == 1">
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="330" v-if="signStatus == 0">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="toWatch(scope.row)">查看
            </el-button>
            <el-button type="primary" size="small" @click="toSign(scope.row, '1')">同意
            </el-button>
            <el-button type="danger" size="small" @click="toSign(scope.row, '2')">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="handleCurrentChange"
        :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
    <!-- 电子合同管理 -->
    <el-dialog class="addDialog" :visible.sync="flag.isExchange" width="700px">
      <span slot="title" class="dialog-title">
        管理电子合同
      </span>
      <el-alert style="margin-bottom:10px;" type="warning" :closable="false"
        title="温馨提示：请仔细管理好您和合作伙伴之间的各类合同；您仅可以管理自己方的合同">
      </el-alert>
      <el-form inline :model="cntSearchForm" ref="cntSearchForm">
        <el-form-item label="合同名称：" prop="contractName" label-width="85px">
          <el-input v-model="cntSearchForm.contractName" placeholder="请输入企业名称" @keyup.enter.native="GetContractInfo()">
          </el-input>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-button type="primary" size="medium" @click="GetContractInfo()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="(cntSearchForm.contractName = ''), GetContractInfo()"
            icon="el-icon-delete">清空</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="pactlist" @selection-change="tableSelectionChange"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="pactLoading">
        <el-table-column align="center" type="index" prop="UserID" label="序号" width="50">
        </el-table-column>
        <el-table-column align="center" prop="ContractName" label="文件名" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ModifyDate" label="修改时间" width="200">
        </el-table-column>
        <el-table-column align="center" label="操作" width="220" fixed="right">
          <template slot-scope="scope">
            <el-button style="padding:0;" v-if="scope.row.ContractMediumType == '01'">
              <div class="up" style="width: 56px;height: 32px;font-size: 12px;line-height: 32px;">
                <input style="width: 56px;height: 32px;" class="upBox" type="file" id="inp" ref="inputer"
                  @change="fileUpload($event, 'list', scope.row)"
                  accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/msword,application/xml-dtd" />
                更新
              </div>
            </el-button>
            <el-button type="success" size="small" @click="upPact(scope.row)">下载</el-button>
            <el-button type="danger" size="small" @click="delPact(scope.row)"
              v-if="scope.row.ContractMediumType == '01'">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="pactChange" :current-page.sync="pactData.page"
        :page-size="pactData.pagesize" layout="total, prev, pager, next, jumper" :total="pactData.total">
      </el-pagination>
      <el-alert type="warning" :closable="false">
        <span slot="title">
          <span> 1.请点击“上传”按钮，新增一份合作文件；</span><br />
          <span> 2.已上传文件，可以更新覆盖、下载到本地、或者删除；</span><br />
          <span> 3.推荐高清PDF扫描文件，请务必保持电子文件的清晰完整。</span><br />
        </span>
      </el-alert>
      <span slot="footer" class="dialog-footer">
        <div class="up" style="text-align: center;margin:10px 48%;">
          <i class="el-icon-upload2"></i>
          <input class="upBox" type="file" id="inp" ref="inputer" @change="fileUpload($event, 'btn')" />
          上传合同
        </div>
      </span>
    </el-dialog>
    <!-- 委托协议 -->
    <el-dialog class="addDialog" :visible.sync="flag.contractListDialog" width="900px">
      <span slot="title" class="dialog-title">
        委托协议
      </span>
      <div style="display: flex;align-items:center;margin-bottom: 10px;">
        <span>合同名称：</span>
        <el-input v-model="name" placeholder="请输入合同名称" style="width:300px;margin: 0px 10px;"></el-input>
        <el-button type="primary" size="medium" @click="getPCT10ContractList" icon="el-icon-search">搜索</el-button>
      </div>
      <el-table :data="contractList" height="286px" :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        v-loading="flag.contractListLoading">
        <el-table-column align="center" type="index" prop="UserID" label="序号" width="50">
        </el-table-column>
        <el-table-column align="center" prop="ContractName" label="文件名" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ModifyDate" label="修改时间" width="200">
        </el-table-column>
        <el-table-column align="center" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button type="success" size="small" @click="upPact(scope.row)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="contractChange" :current-page.sync="contractData.page"
        :page-size="contractData.pagesize" layout="total, prev, pager, next, jumper" :total="contractData.total">
      </el-pagination>
    </el-dialog>
    <!-- 拒绝原因dialog -->
    <el-dialog width="700px" title="拒绝原因" :visible.sync="rejectModal" :close-on-click-modal="false" append-to-body>
      <el-input type="textarea" maxlength="200" placeholder="请输入拒绝原因，限制200个汉字" v-model="signRemark"></el-input>
      <div style="display: flex; justify-content: center; margin-top: 16px">
        <el-button size="mini" @click="rejectModal = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitSign" :disabled="!signRemark">确定
        </el-button>
      </div>
    </el-dialog>
    <!-- 短信验证码dialog -->
    <el-dialog width="450px" title="提示" :visible.sync="codeModal" :close-on-click-modal="false" append-to-body class="codeModal">
      <span style="font-size: 16px;">短信验证码：</span>
      <el-input maxlength="200" placeholder="请输入短信验证码" v-model="code" style="width: 200px;margin-right: 10px;"></el-input>
      <el-button type="primary" @click="submitSign" :disabled="!code">确定
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetEnterAgentInfo,
} from "@/api/goodsOwner/serviceProviderCooperation/index";
import {
  addContract,
  upLoadContract,
  GetContractInfo,
  removeContract,
  getContractList,
  GetContractDriverList,
  GetPCT10ContractList,
} from "@/api/goodsOwner/pactAdmin/index";
import { GetPartners } from '@/api/newRate'
import { EContractSignPage, EContractSignAgree, SendCode, GetContractTemplate } from '@/api/common/common'
import { _getUserId } from "@/utils/storage";
export default {
  data() {
    return {
      name: "",
      // 是否显示电子合同
      flag: {
        isExchange: false,
        contractListLoading: false,
        contractListDialog: false,
      },
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 搜索参数
      searchForm: {
        EnterpriseFullName: "", //企业名称
        dateTime: ["", ""], //合作起始日期
        LegalPersonlPhone: "",
      },
      //表格数据
      tableData: [],
      // 合同分页参数
      pactData: {
        page: 1, //当前所处的页码
        pagesize: 4, //每次请求的数量
        total: 0, //总条数
      },
      // 合同列表
      pactlist: [],
      // 查看合同
      SupplierUserID: "",
      // 服务商列表loading
      loading: true,
      // 合同列表loading
      pactLoading: true,
      // 默认tab栏
      activePage: "0",
      contractList: [],
      contractData: {
        page: 1, //当前所处的页码
        pagesize: 7, //每次请求的数量
        total: 0, //总条数
        contractUserId: "",
      },
      partyBUserID: "", // 当前企业id
      // 管理合同form
      cntSearchForm: {
        contractName: "",
      },
      signStatus: 0, // 0 待签署  1 拒绝  100 已同意
      rejectModal: false,
      signRemark: '',
      signInfo: {},
      signType: null,
      codeModal: false,
      code: null
    };
  },
  methods: {
    toWatch(e) {
      GetContractTemplate({
        id: e.Id
      }).then(res => {
        if (res.data) {
          window.open(res.data)
        } else {
          this.$message.warning("暂无模版可查看！")
        }
      })
    },
    statusChange(e) {
      this.pagination.page = 1;
      this.signStatus = e.paneName;
      this.tableData = []
      this.getSignList()
    },
    // 获取电签列表
    getSignList() {
      this.loading = true;
      let params = {
        status: this.signStatus,
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        aUserID: parseInt(_getUserId()) / 5
      }
      EContractSignPage(params).then(res => {
        this.tableData = res.data.DataList;
        this.pagination.total = res.data.TotalCount;
      }).finally(() => {
        this.loading = false;
      })
    },
    // 同意或拒绝电签
    toSign(item, type) {
      this.signInfo = item
      this.signType = type
      this.$confirm(`确定${type == 1 ? '同意' : '拒绝'}该电签吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (type == 1) {
            SendCode().then(res => {
              this.$message.success("短信已发送，请注意查收！")
              this.codeModal = true
              this.code = ""
            })
          } else {
            this.rejectModal = true
            this.signRemark = ""
          }
        })
        .catch(() => { });
    },
    submitSign() {
      EContractSignAgree({
        id: this.signInfo.Id,
        aUserID: parseInt(_getUserId()) / 5,
        isAgree: this.signType == 1 ? true : false,
        remark: this.signRemark,
        code: this.code
      }).then((res) => {
        this.$message.success("操作成功");
        this.pagination.page = 1;
        this.rejectModal = false
        this.codeModal = false
        this.getSignList()
      });
    },
    // 获取其他合同列表
    getContractList() {
      this.loading = true
      let data = {
        EnterpriseFullName: this.searchForm.EnterpriseFullName,
        InvoiceType: this.searchForm.InvoiceType,
        StartDatetime: this.searchForm.dateTime[0],
        EndDatetime: this.searchForm.dateTime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        LegalPersonlPhone: this.searchForm.LegalPersonlPhone,
      };
      getContractList({ Json: JSON.stringify(data) })
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取委托协议
    goContractList(row) {
      this.contractList = [];
      this.contractData.page = 1;
      this.contractData.contractUserId = row.UserID;
      this.flag.contractListDialog = true;
      this.getPCT10ContractList();
    },
    getPCT10ContractList() {
      this.flag.contractListLoading = true;
      let params = {
        pageSize: this.contractData.pagesize,
        pageIndex: this.contractData.page,
        userID: "",
        name: this.name,
      };
      GetPCT10ContractList(params)
        .then((res) => {
          this.contractList = res.data.DataList;
          this.contractData.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.flag.contractListLoading = false;
        });
    },
    contractChange(e) {
      this.contractData.page = e;
      this.getPCT10ContractList();
    },
    // tab切换
    cmptChange(e) {
      this.pagination.page = 1;
      this.activePage = e.index;
      this.searchForm = {
        EnterpriseFullName: "",
        dateTime: ["", ""],
        LegalPersonlPhone: "",
      };
      if (e.index == 2) {
        // 获取司机数据
        this.getDriverInfo();
      } else if (e.index == 0) {
        // 获取数据列表
        this.getSecPriseList();
      } else if (e.index == 1) {
        this.GetEnterAgentInfo();
      } else if (e.index == 4) {
        this.signStatus = 0
        this.tableData = [];
        this.getSignList()
      } else {
        this.getContractList();
      }
    },
    // 获取代理商
    GetEnterAgentInfo() {
      this.loading = true
      GetEnterAgentInfo({})
        .then((res) => {
          this.tableData = res.enterAgent;
          this.pagination.total = 1;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //获取司机信息
    getDriverInfo() {
      this.loading = true
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        name: this.searchForm.LegalPersonlPhone,
        type: 1,
      };
      GetContractDriverList(params)
        .then((res) => {
          this.tableData = res.driver;
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 上传合同
    fileUpload(event, type, item) {
      this.pactLoading = true;
      let file = event.target.files;
      // 获取最后一个.的位置
      let inx = file[0].name.lastIndexOf(".");
      // 获取后缀
      let ext = file[0].name.substr(inx + 1);
      if (
        [
          "txt",
          "doc",
          "xls",
          "ppt",
          "docx",
          "xlsx",
          "pptx",
          "rtf",
          "pdf",
        ].indexOf(ext.toLowerCase()) !== -1
      ) {
        if (event.target.files[0].size > 20 * 1024 * 1024) {
          this.$message.error("请上传小于 20MB 的文件");
          document.getElementById("inp").value = "";
          this.pactLoading = false;
          return;
        }
        let formData = new FormData();
        formData.append("PartyBUserID", this.partyBUserID);
        formData.append("file", file[0]);
        if (type == "list") {
          formData.append("ContID", item.ID);
        }
        (type == "list" ? upLoadContract : addContract)(formData)
          .then((res) => {
            this.pactData.page = 1;
            this.GetContractInfo();
          })
          .finally(() => {
            this.pactLoading = false;
          });
        document.getElementById("inp").value = "";
      } else {
        this.$message.error("格式错误，请重新上传");
        document.getElementById("inp").value = "";
        this.pactLoading = false;
      }
    },
    // 查看合同
    goFacility(item) {
      this.flag.isExchange = true;
      this.partyBUserID = item.UserID;
      this.cntSearchForm.contractName = "";
      this.pactData.page = 1;
      this.GetContractInfo();
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.selectedTableItemList = e;
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      if (this.activePage == 2) {
        // 获取司机数据
        this.getDriverInfo();
      } else if (this.activePage == 0) {
        this.getSecPriseList();
      } else if (this.activePage == 3) {
        this.getContractList();
      } else if (this.activePage == 1) {
        this.GetEnterAgentInfo();
      } else if (this.activePage == 4) {
        this.getSignList()
      }
    },
    //搜索
    search() {
      this.pagination.page = 1;
      if (this.activePage == 2) {
        // 获取司机数据
        this.getDriverInfo();
      } else if (this.activePage == 0) {
        this.getSecPriseList();
      } else if (this.activePage == 3) {
        this.getContractList();
      } else if (this.activePage == 1) {
        this.GetEnterAgentInfo();
      }
    },
    //清空
    resetForm() {
      //搜索前初始化page
      this.searchForm = {
        EnterpriseFullName: "",
        dateTime: ["", ""],
        LegalPersonlPhone: "",
      };
      this.pagination.page = 1;
      if (this.activePage == 2) {
        // 获取司机数据
        this.getDriverInfo();
      } else if (this.activePage == 0) {
        this.getSecPriseList();
      } else if (this.activePage == 3) {
        this.getContractList();
      } else if (this.activePage == 1) {
        this.GetEnterAgentInfo();
      }
    },
    //获取服务商的货主列表
    getSecPriseList() {
      this.loading = true;
      let params = {
        enterpriseFullName: this.searchForm.EnterpriseFullName,
        createdTimeStart: (this.searchForm.dateTime && this.searchForm.dateTime[0]) ? this.searchForm.dateTime[0] + ' 00:00:00' : '',
        createdTimeEnd: (this.searchForm.dateTime && this.searchForm.dateTime[1]) ? this.searchForm.dateTime[1] + ' 23:59:59' : '',
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        userID: parseInt(_getUserId()) / 5
      }
      GetPartners(params).then(res => {
        this.tableData = res.data.DataList;
        this.pagination.total = res.data.TotalCount;
      }).finally(() => {
        this.loading = false;
      })
    },
    // 获取合同列表
    GetContractInfo() {
      this.pactLoading = true
      let params = {
        pageSize: this.pactData.pagesize,
        pageIndex: this.pactData.page,
        partyBUserID: this.partyBUserID,
        partyUserID: "0", // 只有平台需要传这个值
        contractName: this.cntSearchForm.contractName, // 根据合同名称查询
      };
      if (this.activePage == 2) {
        params.isCheak = true;
      }

      GetContractInfo(params)
        .then((res) => {
          this.pactlist = res.ContractInfo;
          this.pactData.total = Number(res.totalCount);
        })
        .finally(() => {
          this.pactLoading = false;
        });
    },
    // 合同分页
    pactChange(e) {
      this.pactData.page = e;
      this.GetContractInfo();
    },
    // 下载合同
    upPact(item) {
      window.open(item.ContractUrl, "_blank");
    },
    // 删除合同
    delPact(item) {
      this.$confirm("此操作将删除该合同, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeContract({ id: item.ID }).then((res) => {
          this.pactData.page = 1;
          this.GetContractInfo();
        });
      });
    },
  },
  created() {
    // 获取服务商列表
    this.getSecPriseList();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";

.goodsOwnerManage {
  .el-dialog__body {
    padding-top: 0px !important;
  }

  .el-form-item {
    margin-bottom: 14px;
  }

  .head {
    border-bottom: 1px solid $borderGray;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .btns {
    margin-bottom: 20px;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-item {
      width: 50%;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .pagination {
    margin-top: 10px;
  }

  .addDialog {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .up {
      width: 89px;
      height: 40px;
      cursor: pointer;
      position: relative;
      color: #fff;
      background: #409eff;
      font-size: 14px;
      border-radius: 3px;
      line-height: 40px;

      .updiv {
        position: absolute;
        cursor: pointer;
      }

      .upBox {
        width: 89px;
        height: 40px;
        position: absolute;
        left: 0;
        opacity: 0;
        cursor: pointer;
      }
    }

    .body {
      .el-autocomplete {
        width: 100%;
      }

      .choose {
        margin-top: 50px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
<style lang="scss">
.codeModal {
  .el-dialog__body {
    padding-top: 15px !important;
  }
}
</style>